body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hidden-scroll {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  overflow-x: hidden;
}

.hidden-scroll::-webkit-scrollbar {
  width: 0;
}

#root {
  min-height: 100vh;
}

.button-secondary {
  border-radius: 32px !important;
  padding: 2px 16px !important;
  font-size: 14px !important;
}

.pointer {
  cursor: pointer;
}

.d-inline-block {
  display: inline-block;
}
