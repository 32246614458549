.slick-track {
  display: flex !important;
  margin-bottom: 8px;
  margin-top: 8px;
}

.slick-slider {
  .slick-disabled {
    display: none;
  }

  .slick-dots {
    bottom: -20px;
    padding-right: 12px;
  }

  .slick-dots li {
    margin: 0;
  }

  .slick-slide {
    padding-right: 12px;
    height: inherit !important;

    & > div {
      height: 100%;
    }
  }

  .slick-dots li button:before {
    font-size: 9px;
  }
}

.slick-next:before, .slick-prev:before {
  content: none;
}
